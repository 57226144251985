import React from 'react';
import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import help from './ProcessorForm.help';
import { enumToOptions } from '../../../../utils/enumToOptions';
import { ProcessorType } from '../../../../models/enums/ProcessorType';
import { translations } from '../../../../i18n';
import { CodeParser } from '../../../../utils/extractContextVariableNames';
import { ProcessorFormProps } from './ProcessorForm';
import { PipelineElement } from '../../../../models/enums/PipelineElement';
import * as monaco from 'monaco-editor';
function useProcessorFormHook(props: ProcessorFormProps) {
  useResourceBundle(translations, undefined, '442a2262-304f-40a3-89dd-110e23febcbd');
  useHelpBundle(help);

  const { t } = useTranslation();

  const typeOptions = enumToOptions(ProcessorType);

  const codeChecker = (code: string) => {
    return !CodeParser.validate(code);
  };
  const formSaveHandler = (
    data: Partial<{
      type: PipelineElement;
      name: string;
      method: ProcessorType;
      id: string;
      sourceCode: string;
      error: boolean;
    }>,
  ) => {
    let newObject = {
      ...props.processor,
      name: data.name ?? '',
      sourceCode: props.processor.sourceCode,
      error: props.processor.error
    };
    props.updateProcessor(newObject);
  };
  const codeSaveHandler = (code: string | undefined) => {

    if (code) {
      let newObject = {
        ...props.processor,
        sourceCode: code,
      };
      props.updateProcessor(newObject);
    }
  };

  const codeErrorParser = (marker:monaco.editor.IMarker[]) => {
    const isValid = marker.some(x=>x.severity ===8)
      let newObject = {
        ...props.processor,
        error: isValid,
      };
      props.updateProcessor(newObject);

  }

  const exampleCode =
`if (context.id < 5) {
  httpResponse(400, \`Value \${context.id} is too small\`);
}
//input must be provided from a previous processor or endpoint
 nextBody = input.Content.map(x => ({
  ...x,
  id: x.id * context.value
}));`


  return {
    t,
    typeOptions,
    formSaveHandler,
    codeChecker,
    codeSaveHandler,
    codeErrorParser,
    exampleCode
  };
}

export default useProcessorFormHook;
